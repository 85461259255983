
.category-list {
    margin-top: 6rem;
    width: 95%;
    margin: 6rem auto;
    text-align: center;
}

.category-card {
    width: 400px;
    height: 420px;
    margin: 15px auto;
    background-size: 400px 450px; 
    background-position: center;
    background-repeat: no-repeat;
    transition: transform 0.3s ease;
}

.slick-slide {
    transition: all 0.3s ease;
    opacity: 0.2; /* Opacidade reduzida para itens fora do centro */
}
  
.slick-center {
    opacity: 1; /* Item do centro com opacidade total */
    transform: scale(1.1); /* Ajuste o tamanho conforme necessário */
}

@media (max-width: 600px) {
    .category-list {
        width: 90%;
    }
    
    .category-card {
        width: 250px;
        height: 370px;
        background-size: 250px 370px; 
    }
  }