.player-component {
    padding-top: 1rem;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
}

.player-component, .rhap_container {
    background-color: #0b0c0c;
}

.rhap_button-clear:hover {
    color: var(--cor-primaria);
}

.player-component h3 {
    color: var(--cor-primaria);
}

.rhap_container {
    box-shadow: none;
}

.player-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 1rem;
}

.shuffle-button {
    background: none;
    border: none;
    color: #fff;
    font-size: 1.5rem;
    cursor: pointer;
    transition: color 0.3s ease;
}

.shuffle-button.active {
    color: var(--cor-primaria);
}

.shuffle-button:hover {
    color: var(--cor-primaria);
}