@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

:root {
    --cor-primaria: #22C55E; 
    --cor-texto: #e9ecef;
}

.pendrive-background-text {
    color: var(--cor-primaria);
}

body {
    background-color: #000000;
    font-family: 'Roboto', sans-serif;
}

h1, h2 {
    font-weight: 900;
}

h2 {
    font-size: 40px;
    text-transform: uppercase;
    color: var(--cor-texto);
    margin: 20px 0;
}

.sidebar {
    z-index: 1000;
    background-color: rgba(27, 29, 31, 0.37); /* Cor quase transparente (80% opacidade) */
    backdrop-filter: blur(8px); /* Efeito de desfoque */
}

.navbar-color {
    background-color:  #1b1d1f;
}

.menu-selected {
    color: var(--cor-primaria);
}


::-webkit-scrollbar {
    width: 12px; 
}


::-webkit-scrollbar-thumb {
    background-color: #221f20; 
    border-radius: 5px; 
}


::-webkit-scrollbar-track {
    background-color: #3533341c;
}

.auth-page {
    width: 100%;
    height: 100vh;
}

.login {
    background-color: #35333457;
}

.logo-login {
    display: flex;
    justify-content: center;
}

.logo-login img {
    width: 150px;
}

.suporte-login {
    display: flex;
    justify-content: center;
    padding-top: 20px;
}