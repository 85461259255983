
.artist-list {
    text-align: center;
}

.artist-card {
    background-color: rgba(19, 19, 19, 0.507);
    transition: background-color 0.3s;
    cursor: pointer;
    border-radius: 50px;
    padding: 7px 15px;
    color: #fff;
  }
  
  .artist-card.selected {
    border: 2px solid var(--cor-primaria); 
    background-color: #10b9813b;
  }
