
.song-list {
    margin-bottom: 15rem;
}

.song-card {
    color: #fff;
    background-color: rgba(19, 19, 19, 0.507);
    transition: background-color 0.3s; 
}

.song-card.selected {
    background-color: var(--cor-primaria);
    background-color: #10b9811f;
}
